<template>
  <div>
    <b-list-group>
      <b-list-group-item
        v-for="(reference, rKey) in startupReport.references"
        :key="rKey"
      >
        {{ reference.name }}
        <div class="font-small-1 text-success">
          {{ reference.company }}
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'

export default {
  name: 'References',
  components: {
    BListGroup, BListGroupItem,
  },
  computed: {
    startupReport() {
      return this.$store.getters['dealerReportStartupReport/getStartupReport']
    },
  },
}
</script>

<style scoped>

</style>
