<template>
  <div>
    <div v-if="startupReportError === 'PACKAGE_ERROR'">
      <b-alert
        variant="warning"
        show
      >
        <div class="alert-body text-center">
          <FeatherIcon
            icon="InfoIcon"
            size="24"
            class="mt-1 mb-1"
          />
          <p class="lead">
            {{ $t('Raporu görüntülemek için lütfen paket satın alın.') }}
          </p>
        </div>
      </b-alert>
      <view-packages :startup-id="this.$route.params.id" />
    </div>
    <div v-else>
      <route-buttons />
      <b-card :title="$t('Girişim Raporu')">
        <b-row>
          <b-col>
            <startup-card />
          </b-col>
        </b-row>
      </b-card>
      <b-card :title="$t('Girişim Özeti')">
        <b-row class="match-height">
          <b-col
            md="3"
          >
            <activity-area />
          </b-col>
          <b-col
            md="3"
          >
            <marketing-focus />
          </b-col>
          <b-col
            md="3"
          >
            <target-customers />
          </b-col>
          <b-col md="3">
            <team />
          </b-col>
        </b-row>
      </b-card>
      <b-row class="match-height">
        <b-col md="6">
          <b-card :title="$t('Problemler')">
            <problems />
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card :title="$t('Çözümler')">
            <solutions />
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card :title="$t('Pazar')">
            <market />
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card :title="$t('Gelir Modeli')">
            <revenue-model />
          </b-card>
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col md="6">
          <b-card :title="$t('Rekabet')">
            <competitive-advantages />
          </b-card>
          <b-card :title="$t('Alınan Destekler')">
            <award-supports />
          </b-card>
          <b-card :title="$t('Referanslar')">
            <references />
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card :title="$t('Finansal İhtiyaç')">
            <demand-investment />
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BAlert,
} from 'bootstrap-vue'
import startupCard from '@/views/Dealers/Reports/components/startup_report/Startup_card.vue'
import activityArea from '@/views/Dealers/Reports/components/startup_report/Activity_area.vue'
import marketingFocus from '@/views/Dealers/Reports/components/startup_report/Marketing_focus.vue'
import targetCustomers from '@/views/Dealers/Reports/components/startup_report/Target_customers.vue'
import team from '@/views/Dealers/Reports/components/startup_report/Team.vue'
import problems from '@/views/Dealers/Reports/components/startup_report/Problems.vue'
import solutions from '@/views/Dealers/Reports/components/startup_report/Solutions.vue'
import market from '@/views/Dealers/Reports/components/startup_report/Market.vue'
import revenueModel from '@/views/Dealers/Reports/components/startup_report/Revenue_model.vue'
import competitiveAdvantages from '@/views/Dealers/Reports/components/startup_report/Competitive_advantages.vue'
import demandInvestment from '@/views/Dealers/Reports/components/startup_report/Demand_investment.vue'
import awardSupports from '@/views/Dealers/Reports/components/startup_report/Award_supports.vue'
import references from '@/views/Dealers/Reports/components/startup_report/References.vue'
import routeButtons from '@/views/Dealers/Reports/components/Route_buttons.vue'
import ViewPackages from '@/views/App/Packages/ViewPackages.vue'

export default {
  name: 'StartupReport',
  components: {
    BCard,
    BRow,
    BCol,
    BAlert,
    startupCard,
    activityArea,
    marketingFocus,
    targetCustomers,
    team,
    problems,
    solutions,
    market,
    revenueModel,
    competitiveAdvantages,
    demandInvestment,
    awardSupports,
    references,
    routeButtons,
    ViewPackages,
  },
  computed: {
    startupReportError() {
      return this.$store.getters['dealerReportStartupReport/getStartupReportError']
    },
    startupReports() {
      return this.$store.getters['dealerReportStartupReport/getStartupReports']
    },
  },
  created() {
    this.getStartupReport()
    this.getStartupReports()
  },
  methods: {
    getStartupReport() {
      this.$store.dispatch('dealerReportStartupReport/startupReport', this.$route.params.id)
    },
    getStartupReports() {
      this.$store.dispatch('dealerReportStartupReport/getReports', this.$route.params.id)
    },
  },
}
</script>
