<template>
  <div>
    <b-card-body class="d-flex justify-content-between align-items-center">
      <div class="truncate">
        <h4 class="mb-1 font-weight-bolder">
          {{ $t('Hedef Müşteriler') }}
        </h4>
        {{ startupReport.target_customers }}
      </div>
      <b-avatar
        variant="light-danger"
        size="45"
      >
        <feather-icon
          size="21"
          icon="TargetIcon"
        />
      </b-avatar>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCardBody, BAvatar,
} from 'bootstrap-vue'

export default {
  name: 'TargetCustomers',
  components: {
    BCardBody,
    BAvatar,
  },
  computed: {
    startupReport() {
      return this.$store.getters['dealerReportStartupReport/getStartupReport']
    },
  },
}
</script>

<style scoped>

</style>
