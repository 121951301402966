<template>
  <b-card-body class="d-flex justify-content-between">
    <div class="truncate">
      <h4 class="mb-1 font-weight-bolder">
        {{ $t('Pazar Odağı') }}
      </h4>
      <b-badge
        v-for="(focus, key) in startupReport.marketing_focus"
        :key="key"
        pill
        variant="light-primary"
        class="mr-1"
      >
        {{ focus }}
      </b-badge>
    </div>
    <b-avatar
      variant="light-primary"
      size="45"
    >
      <feather-icon
        size="21"
        icon="CropIcon"
      />
    </b-avatar>
  </b-card-body>
</template>

<script>
import { BCardBody, BAvatar, BBadge } from 'bootstrap-vue'

export default {
  name: 'MarketingFocus',
  components: {
    BCardBody,
    BAvatar,
    BBadge,
  },
  computed: {
    startupReport() {
      return this.$store.getters['dealerReportStartupReport/getStartupReport']
    },
  },
}
</script>

<style scoped>

</style>
